(function() {
  document.addEventListener('DOMContentLoaded', function() {
    const accordionHeaders = document.querySelectorAll('.c-areas-chara-search__area-header');
    
    accordionHeaders.forEach(header => {
      header.addEventListener('click', () => {
        const parent = header.closest('.c-areas-chara-search__area');
        const content = parent.querySelector('.c-areas-chara-search__prefectures');
        const arrow = header.querySelector('svg');
        
        // Toggle active state
        parent.classList.toggle('is-open');
        
        // Animate content height
        if (parent.classList.contains('is-open')) {
          content.style.maxHeight = content.scrollHeight + 'px';
          arrow.style.transform = 'rotate(180deg)';
        } else {
          content.style.maxHeight = '0';
          arrow.style.transform = 'rotate(0)';
        }
      });
    });
  });
})(); 